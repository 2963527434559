import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { useSharedContent } from 'src/hooks/customGraphqlHooks';
import Mustache from 'mustache';

const useStyles = makeStyles(theme => ({
    weightBiasPagination: {
        padding: "0.625rem 0",
        fontFamily: "Roboto Condensed",
        fontSize: "1.1875rem",
        textAlign: "center",
        borderBottom: "0.0625rem solid #707070",
        width: "100%"
    }
}))

interface IProps {
    totalPages: number
    page: number
}



const WeightBiasPagination: React.FC<IProps> = ({ totalPages, page }) => {
    const classes = useStyles();
    const sharedContent = useSharedContent();

    var data = { currentPage: page, totalPages:totalPages };

    return useMemo(() => (
        <div className={classes.weightBiasPagination}>            
            <span>{Mustache.render(sharedContent.pagination,data)}</span>  
        </div>
    ), [totalPages, page, classes])

}

export default WeightBiasPagination;