import React, { forwardRef, useCallback, useEffect, ForwardedRef } from 'react';
import { Button, ButtonProps } from '@material-ui/core';

interface IProps extends ButtonProps {
    onKeyPressed?: (key: string) => void;
}

function KeybindButton({ onKeyPressed, ...props }: IProps, ref: ForwardedRef<HTMLButtonElement>) {
    const handleKeyPress = useCallback((event: KeyboardEvent) => {
        if (!onKeyPressed) {
            return;
        }

        onKeyPressed(event.key);
    }, [onKeyPressed]);

    useEffect(() => {
        if (!onKeyPressed) {
            return;
        }

        document.addEventListener("keydown", handleKeyPress);

        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        }
    }, [handleKeyPress])

    return (
        <Button
            {...props}
            ref={ref}
        >
            {props.children}
        </Button>
    )
}

export default forwardRef(KeybindButton);