import { useStaticQuery, graphql } from "gatsby"
import { ContentfulSharedContentQuery } from "types/graphql-types"
import { useLanguageDataGetter } from "./useLanguageData"

type EdgeType = ContentfulSharedContentQuery["allContentfulSharedContent"]["edges"][0]

export const useSharedContent = () => {
  const data = useStaticQuery<ContentfulSharedContentQuery>(
    graphql`
      query ContentfulSharedContent {
        allContentfulSharedContent {
          edges {
            node {
              node_locale
              logo {
                file {
                  url
                  contentType
                }
              }
              pagination
              continueButtonLabel
              submitButtonLabel
              skipButtonLabel
              backgroundLinkLabel
              privacyLinkLabel
              aboutLinkLabel
              implicitAssociationTutorialLeftHand {
                id
                contentful_id
                file {
                  url
                  contentType
                }
                fixed {
                  ...GatsbyContentfulFixed
                }
                fluid {
                  ...GatsbyContentfulFluid
                }
              }
              implicitAssociationTutorialRightHand {
                id
                contentful_id
                file {
                  url
                  contentType
                }
                fixed {
                  ...GatsbyContentfulFixed
                }
                fluid {
                  ...GatsbyContentfulFluid
                }
              }
              implicitAssociationTutorialDKey {
                id
                contentful_id
                file {
                  url
                  contentType
                }
                fixed {
                  ...GatsbyContentfulFixed
                }
                fluid {
                  ...GatsbyContentfulFluid
                }
              }
              implicitAssociationTutorialKKey {
                id
                contentful_id
                file {
                  url
                  contentType
                }
                fixed {
                  ...GatsbyContentfulFixed
                }
                fluid {
                  ...GatsbyContentfulFluid
                }
              }
              implicitAssociationTutorialRightFinger {
                id
                contentful_id
                file {
                  url
                  contentType
                }
                fixed {
                  ...GatsbyContentfulFixed
                }
                fluid {
                  ...GatsbyContentfulFluid
                }
              }
              implicitAssociationTutorialLeftFinger {
                id
                contentful_id
                file {
                  url
                  contentType
                }
                fixed {
                  ...GatsbyContentfulFixed
                }
                fluid {
                  ...GatsbyContentfulFluid
                }
              }
              separatorTextLabel
            }
          }
        }
      }
    `
  )

  const sharedContent = useLanguageDataGetter<EdgeType>(
    data.allContentfulSharedContent
  )
  const contentfulSharedContent = sharedContent.node
  return contentfulSharedContent
}
