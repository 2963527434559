import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ContentfulAsset as ContentfulAssetType } from "types/graphql-types";
import VideoPlayer from "../components/video/video-player";
import { useDispatch } from "react-redux";
import { disableButton, enableButton } from "src/state/features/ui/uiSlice";
import { graphql } from "gatsby";

const useStyles = makeStyles({
  root: {
  },
});

export type ContentfulVideoType = {
  title: string,
  video: ContentfulAssetType
  disableUntilFinished: boolean

}

export default function ContentfulVideo({ title, video, disableUntilFinished }: ContentfulVideoType) {
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    if (disableUntilFinished) {
      dispatch(disableButton());
    }
  }, [disableUntilFinished]);

  function handleVideoEnd() {
    dispatch(enableButton());
  }

  return (
    <div className={classes.root}>
      <VideoPlayer url={video?.file?.url as string} onVideoEnded={handleVideoEnd} />
    </div>
  );
}

export const videoFragment = graphql`
fragment videoFragment on ContentfulVideo {
    id
    title
    contentful_id
    disableUntilFinished
    video {
      id
      file {
        contentType
        url
      }
    }
  }
`