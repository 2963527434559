import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';


/**
 * Datasets item container
 * Functions on an individual dataset item
 * 
 * Delete, archive, view, etc
 */

const useStyles = makeStyles(theme => ({
    surveyHeader: {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "space-between",
        marginBottom: "1rem",
        marginTop: "1.25rem",
        borderBottom: "0.0625rem solid #707070",
    },
    surveyTitle: {
        display: "block",
        margin: 0,
        color: "#DB5205",
        fontSize: "1.625rem",
        fontWeight: 100,
    },
    pagination: {
        display: "block",
        marginBottom: "0.3125rem",
        color: "black",
        fontSize: "0.875rem",
    },
}))

interface IProps {
    header: string
    currentStep: number
    allSteps: number
}


const PaginationHeader: React.FC<IProps> = ({ header, currentStep, allSteps }) => {
    const classes = useStyles();
    return useMemo(() => (
        <div className={classes.surveyHeader}>
            <h1 className={classes.surveyTitle}>{header}</h1>
            {currentStep !== 0 && allSteps !== 0 &&
                <span className={classes.pagination}>{currentStep}/{allSteps}</span>
            }
        </div>
    ), [header, currentStep, allSteps, classes])
}

export default PaginationHeader;