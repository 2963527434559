import React from 'react';
import { makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    button: {
        minWidth: "6.75rem",
        textTransform: "none",
        width: "100%",
        height: "4.5rem",
    },
}))

interface IProps {
    label: string
    onClickHandler?: () => void
    isDisabled?: boolean
    fullWidth?: boolean
}

const AssessmentButton: React.FC<IProps> = ({ label, onClickHandler, isDisabled = false, fullWidth = false }) => {
    const classes = useStyles();

    return (
        <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={onClickHandler}
            fullWidth={fullWidth}
            disabled={isDisabled}
        >
            {label}
        </Button>
    )
}

export default AssessmentButton;