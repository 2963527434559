import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import { ParametrizedSelector } from "src/hooks/useParamSelector";
import { ISurveyState , SurveySectionData } from "./surveySlice";

const getId = (_: any, id: any | undefined) => id;
const _getSurvey = (state: { surveyStore: ISurveyState }) => state.surveyStore;
const _getSurveyData = (state: { surveyStore: ISurveyState }) => state.surveyStore.surveyData;

export const getActiveSurveyModule = (state: { surveyStore: ISurveyState }) => state.surveyStore.activeModule;

export const getCurrentSurveyId = createSelector(
    _getSurveyData,
    (survey) => {
        if (survey) {
            return survey.surveyId;
        }
        return null;
    }
);

export const getSurveyResults = createSelector(
    _getSurvey,
    (survey) => {
        if (survey) {
            return survey.implicitBiasResult;
        }
        return null;
    }
);

export const getTestSummary = createSelector(
    _getSurvey,
    (survey) => {
        if (survey) {
            return survey.summary;
        }
        return null;
    }
);


export const getSurveyDataByName = (): ParametrizedSelector< string, SurveySectionData | null> => {
    return createSelector(
        _getSurveyData,
        getId,
        (surveyData, id) => {
            if (surveyData && id) {
                const { sections } = surveyData;
                if (!_.isEmpty(sections)) {
                    const value = _.get(sections, id);
                    return value;
                }
            }
            return null;
        })
}