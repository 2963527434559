import React, { Fragment } from "react"
import { Grid, makeStyles } from "@material-ui/core"

/**
 * Graph Container
 * This is the container for displaying different graphs
 *
 */

const useStyles = makeStyles((theme) => ({
  legendContainer: {
    display: "flex",
  },
  bullet: {
    width: "0.8em",
    height: "0.8em",

    display: "inline-block",
  },
  activeLabel: {
    borderBottom: "1px solid black",
    lineHeight: " 0.4em",
    margin: "0.6em 5px 0.1em",
    "& span": {
      background: "white",
      padding: "0 5px",
    },
  },
  border: {
    border: "5px solid #DB5205",
    padding: "0 10px",
  },
}))

interface IProps {
  data: { label: string; color: string }[]
  activeLegendText: string
  activeIndex: number
}

const CustomLegend: React.FC<IProps> = ({
  data,
  activeLegendText,
  activeIndex,
}) => {
  const classes = useStyles()

  return (
    <Grid
      container
      spacing={0}
      className={classes.legendContainer}
      justify="center"
    >
      {data.map((d, index) => (
        <Fragment key={index}>
          <Grid item xs={3}>
            {index === activeIndex && (
              <div className={classes.activeLabel}>
                <span>{activeLegendText}</span>
              </div>
            )}
          </Grid>
          <Grid
            item
            xs={9}
            className={index === activeIndex ? classes.border : ""}
          >
            <div
              className={classes.bullet}
              style={{ backgroundColor: d.color }}
            ></div>{" "}
            {d.label}
          </Grid>
        </Fragment>
      ))}
    </Grid>
  )
}

export default CustomLegend
