import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Img, { FixedObject, FluidObject } from "gatsby-image";
import VideoPlayer from "src/components/video/video-player";
import { ContentfulAsset as ContentfulAssetType } from "types/graphql-types";
import { graphql } from "gatsby";

const useStyles = makeStyles({
  root: {
  },
});

export default function ContentfulAsset({ file, fixed, fluid }: ContentfulAssetType) {
  const classes = useStyles();

  if (fluid)
    return <Img fluid={fluid as FluidObject} />
  if (fixed)
    return <Img fixed={fixed as FixedObject} />
  else {
    const assetType = file?.contentType?.split('/')[0]
    if (assetType === "video")
      return <VideoPlayer url={file?.url as string} />
    if (file?.contentType === "image/svg+xml")
      return <img src={file.url as string} />
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg">
      <rect fill="#ddd" width="100%" height="100%" />
      <line stroke="#000" x1="0" y1="0" x2="100%" y2="100%"></line>
      <line stroke="#000" x1="0" y1="100%" x2="100%" y2="0"></line>
    </svg>
  )
}


export const assetFragment = graphql`
fragment assetFragment on ContentfulAsset {
    title
    description
    contentful_id
    file {
      url
      contentType
    }
    fixed {
      ...GatsbyContentfulFixed
    }
    fluid {
      ...GatsbyContentfulFluid
    }
  }
`