import React from "react"
import Header from "./header"
import { Grid, makeStyles } from "@material-ui/core"
import { useHasMounted } from "src/hooks/useHasMounted"
import { PaginationHeader } from "../pagination"
import WeightBiasPagination from "../pagination/weight-bias-pagination"
import Footer from "./footer"
import { ButtonType } from "../buttons/styled-button"

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    backgroundColor: "#fff",
    "@media (min-width: 1280px)": {
      maxWidth: "66.667vh",
    },
  },
  layoutContainer: {
    minHeight: "100vh",
    height: "cal(100vh - calc(100vh - 100%))",
    maxWidth: "90vw",
    margin: "0 auto",
  },
  link: {
    color: "#737373",
    fontFamily: "Roboto",
    fontSize: "0.8125rem",
  },
  linkWrapper: {
    justifyContent: "space-between",
    marginBottom: 0,
  },
}))

interface IProps {
  children: any
  title?: string
  footerProps?: IFooterProps
  headerProps?: IHeaderProps
}

export interface IFooterProps {
  hideSubmit?: boolean
  submitButton?: {
    label?: string
    type?: ButtonType
    isDisabled?: boolean
    onClickHandler: (arg?: any) => any
    onKeyPressed?: (key: string) => void
  }
  allowSkip?: boolean
  skipButton?: {
    label?: string
    onClickHandler: (arg?: any) => any
  }
}

export interface IHeaderProps {
  type: headerTypes
  title?: string
  step?: number
  allSteps?: number
}

export enum headerTypes {
  none = "none",
  logo = "logo",
  contentPagination = "content-pagination",
  questionPagination = "question-pagination",
}

const CustomLayout = ({ children, headerProps, footerProps }: IProps) => {
  const classes = useStyles()
  const hasMounted = useHasMounted()

  if (!hasMounted) {
    return null
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.layoutContainer} direction="column">
        {headerProps && (
          <Grid container>
            {headerProps?.type === headerTypes.logo && (
              <Header siteTitle="Learning Centre" />
            )}
            {headerProps?.type !== (headerTypes.logo || headerTypes.none) && (
              <Grid item xs={12}>
                {headerProps?.type === headerTypes.questionPagination && (
                  <WeightBiasPagination
                    page={headerProps?.step!}
                    totalPages={headerProps?.allSteps!}
                  />
                )}
                {headerProps?.type === headerTypes.contentPagination && (
                  <PaginationHeader
                    header={headerProps?.title!}
                    currentStep={headerProps?.step!}
                    allSteps={headerProps?.allSteps!}
                  />
                )}
              </Grid>
            )}
          </Grid>
        )}
        <Grid container style={{ flex: "1 0 auto" }}>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
        {footerProps && (
          <Grid container style={{ marginTop: "auto" }}>
            <Footer
              footerProps={footerProps}
              showFooterLinks={headerProps?.type === headerTypes.logo}
            />
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default CustomLayout
