import React from "react"
import { makeStyles } from "@material-ui/core"
import GaugeChart from "react-gauge-chart"
import CustomLegend from "./legend"
import { useSelector } from "react-redux"
import {
  getSurveyResults,
  getTestSummary,
} from "src/state/features/survey/surveySelector"
import {
  ImplicitBiasInterpretation,
  ImplicitBiasInterpretationDirection,
} from "../../../external-api/license-api"

/**
 * Graph Container
 * This is the container for displaying different graphs
 *
 */

const useStyles = makeStyles((theme) => ({
  text: {},
}))

interface IProps {
  chartProps: { color: string; label: string }[]
  activeLegendText: string
}

const GaugeChartContainer: React.FC<IProps> = ({
  chartProps,
  activeLegendText,
}) => {
  const results = useSelector(getSurveyResults)
  const summary = useSelector(getTestSummary)

  function getChartData() {
    let cData: number[] = [0, 0, 0, 0, 0, 0, 0]
    const buckets = summary?.interpretationItems
    if (buckets) {
      buckets.forEach((b) => {
        const direction =
          b.direction === ImplicitBiasInterpretationDirection.NUMBER_0 ? 0 : 1
        switch (b.interpretation) {
          case ImplicitBiasInterpretation.NUMBER_1: {
            cData[3] = cData[3] + b.count
            break
          }
          case ImplicitBiasInterpretation.NUMBER_2: {
            direction == 1 ? (cData[0] = b.count) : (cData[4] = b.count)
            break
          }
          case ImplicitBiasInterpretation.NUMBER_3: {
            direction == 1 ? (cData[1] = b.count) : (cData[5] = b.count)
            break
          }
          case ImplicitBiasInterpretation.NUMBER_4: {
            direction == 1 ? (cData[2] = b.count) : (cData[6] = b.count)
            break
          }
        }
      })
    }

    return cData
  }

  function getActiveBucketIndex() {
    const score = results?.implicitBiasScore ?? 0
    const userDirection = score > 0 ? 0 : 1
    const userInterpretation = results?.implicitBiasInterpretation

    if (userInterpretation === ImplicitBiasInterpretation.NUMBER_2) {
      if (userDirection === ImplicitBiasInterpretationDirection.NUMBER_1)
        return 2
      if (userDirection === ImplicitBiasInterpretationDirection.NUMBER_0)
        return 4
    }
    if (userInterpretation === ImplicitBiasInterpretation.NUMBER_3) {
      if (userDirection === ImplicitBiasInterpretationDirection.NUMBER_1)
        return 1
      if (userDirection === ImplicitBiasInterpretationDirection.NUMBER_0)
        return 5
    }
    if (userInterpretation === ImplicitBiasInterpretation.NUMBER_4) {
      if (userDirection === ImplicitBiasInterpretationDirection.NUMBER_1)
        return 0
      if (userDirection === ImplicitBiasInterpretationDirection.NUMBER_0)
        return 6
    }
    if (userInterpretation === ImplicitBiasInterpretation.NUMBER_1) {
      return 3
    }
    return -1
  }

  function returnChartData() {
    const index = getActiveBucketIndex()
    const chartData = getChartData()
    const chartArcs = chartData.map((x) => x / totalParticipants)
    let totalPercent = 0
    if (index !== -1) {
      for (let i = 0; i < index; i++) {
        totalPercent += chartArcs[i]
      }
      totalPercent += chartArcs[index] / 2
    }
    return {
      activeBucket: totalPercent,
      activeBucketIndex: index,
      chartArcs: chartArcs,
      data: chartData,
    }
  }

  const totalParticipants = summary?.total || 1
  const { activeBucket, chartArcs, data, activeBucketIndex } = returnChartData()

  const colors = chartProps.map((x) => x.color)

  const legendData = chartProps.map((x, index) => {
    return {
      label: `${x.label} (${((data[index] / totalParticipants) * 100).toFixed(
        2
      )}%)`,
      color: x.color,
    }
  })

  return (
    <div style={{ width: "100%" }}>
      <GaugeChart
        marginInPercent={0.02}
        id="gauge-chart"
        arcsLength={chartArcs}
        colors={colors}
        percent={activeBucket}
        arcPadding={0.02}
        hideText={true}
      />
      <br />
      <CustomLegend
        data={legendData}
        activeLegendText={activeLegendText}
        activeIndex={activeBucketIndex}
      />
    </div>
  )
}

export default GaugeChartContainer
