import React, { ReactNode } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS, Block, Inline } from "@contentful/rich-text-types"
import ContentfulAsset from "src/contentful/contentful-asset"
import ContentfulVideo, { ContentfulVideoType } from "./contentful-video"
import { useSelector } from "react-redux"
import { getSurveyResults } from "src/state/features/survey/surveySelector"
import { GaugeChart } from "../components/visualization"

const useStyles = makeStyles({
  richTextRoot: {
    wordBreak: "break-word",
  },
  paragraph: {
    whiteSpace: "pre-wrap",
  },
  head: {
    marginTop: "0.07rem",
  },
})

export type ContentfulRichTextType =
  RenderRichTextData<ContentfulRichTextGatsbyReference>

export default function ContentfulRichText(data: ContentfulRichTextType) {
  const classes = useStyles()
  const options = {
    renderMark: {},
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: ReactNode) => (
        <Typography variant="body2" paragraph>
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_1]: (node: Block | Inline, children: ReactNode) =>
        (
          <Typography variant="h1" component="h2" className={classes.paragraph}>
            {children}
          </Typography>
        ) as ReactNode,
      [BLOCKS.HEADING_2]: (node: Block | Inline, children: ReactNode) =>
        (
          <Typography variant="h2" className={classes.head}>
            {children}
          </Typography>
        ) as ReactNode,
      [BLOCKS.HEADING_3]: (node: Block | Inline, children: ReactNode) =>
        (<Typography variant="h3">{children}</Typography>) as ReactNode,
      [BLOCKS.HEADING_4]: (node: Block | Inline, children: ReactNode) =>
        (<Typography variant="h4">{children}</Typography>) as ReactNode,
      [BLOCKS.HEADING_5]: (node: Block | Inline, children: ReactNode) =>
        (<Typography variant="h5">{children}</Typography>) as ReactNode,
      [BLOCKS.HEADING_6]: (node: Block | Inline, children: ReactNode) =>
        (<Typography variant="h6">{children}</Typography>) as ReactNode,
      [BLOCKS.EMBEDDED_ASSET]: (node: any) =>
        (<ContentfulAsset {...node.data.target} />) as ReactNode,
      [BLOCKS.EMBEDDED_ENTRY]: (node: Block | Inline) => {
        switch (node.data.target.__typename) {
          case "ContentfulVideo": {
            return (
              <ContentfulVideo {...node.data.target} />
            ) as unknown as ContentfulVideoType
          }
          case "ContentfulGaugeChart": {
            return (
              <GaugeChart
                chartProps={node.data.target.chartData.data}
                activeLegendText={node.data.target.activeLegendText}
              />
            )
          }
        }
        return (<></>) as ReactNode
      },
    },
  }

  return (
    <>
      {data && (
        <div className={classes.richTextRoot}>
          {renderRichText(data, options)}
        </div>
      )}
    </>
  )
}
