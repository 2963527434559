import React, { useEffect } from "react";
import ReactPlayer from 'react-player'
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
  },
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%" /* Player ratio: 100 / (1280 / 720) */
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0
  }
});

interface IProps {
  url: string,
  useControls?: boolean
  onVideoEnded?: () => any
}

export default function VideoPlayer({ url, useControls = true, onVideoEnded }: IProps) {
  const classes = useStyles();

  return (
    <div className={classes.playerWrapper}>
      <ReactPlayer
        className={classes.reactPlayer}
        url={url}
        width='100%'
        height='100%'
        controls={useControls}
        playing={true}
        onEnded={onVideoEnded}
      />
    </div>
  );
}
