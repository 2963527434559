import React, { Fragment } from 'react';
import { AppBar, Divider, makeStyles, Toolbar, Typography } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from 'gatsby-theme-material-ui';
import ContentfulAsset from "src/contentful/contentful-asset";
import { useSharedContent } from 'src/hooks/customGraphqlHooks';
import { useHasMounted } from 'src/hooks/useHasMounted';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
  link: {
    marginLeft: theme.spacing(2),
  },
  root: {
    flex: "0 0 auto",
    alignItems: "center",
    flexWrap: "wrap",
    padding: "0.25rem 0 0 0",
    width: "100%",
    '& hr': {
      width: "100%",
      marginTop: "0.25rem",
    }
  },
  logo: {
    width: "10rem",
    height: "5.75rem",
    borderRadius: "10%",
    marginTop: "10px",
  },
  header: {
    color: "#DB5205",
    fontSize: "1.7rem !important",
    lineHeight: "2rem !important",
    display: "inline-block",
    fontFamily: "open sans",
    fontWeight: 300,
    verticalAlign: "top",
    border: "none",
    marginBottom: "20.125rempx",
    marginTop: "1.625rem !important",
    float: "right"
  },
  regular: {
    width: "100%",
    display: "block",
  },
}))

interface IProps {
  siteTitle: string
}

const HeaderContainer: React.FC<IProps> = ({ siteTitle }: IProps) => {
  const classes = useStyles();
  const data = useSharedContent();
  const hasMounted = useHasMounted();

  if (!hasMounted) {
    return null;
  }

  function navigateHome(event: any) {
    // history.push(Paths.HOME);
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent" elevation={0} >
        <Toolbar disableGutters className={classes.regular}>
          <img src={data?.logo?.file?.url || ""} alt="logo" className={classes.logo} />
          <Typography className={classes.header} color="primary" >
            Learning <br /> Center
          </Typography>
        </Toolbar>
      <hr style={{}} />
      </AppBar>
    </div >
  );
};

export default HeaderContainer;