import React from "react"
import Header from "./header"
import { Grid, makeStyles } from "@material-ui/core"
import { useHasMounted } from "src/hooks/useHasMounted"
import { PaginationHeader } from "../pagination"
import { StyledButton } from "../buttons"
import WeightBiasPagination from "../pagination/weight-bias-pagination"
import { Paths } from "src/utilities/constants"

import { Link } from "gatsby-theme-material-ui"
import { IFooterProps } from "./custom-layout"
import { ButtonType } from "../buttons/styled-button"
import { useSharedContent } from "src/hooks/customGraphqlHooks"

const useStyles = makeStyles(() => ({
  button: {
    marginBottom: "1rem",
  },
  link: {
    color: "#737373",
    fontFamily: "Roboto",
    fontSize: "0.8125rem",
  },
  linkWrapper: {
    justifyContent: "space-between",
    marginBottom: 0,
  },
}))

interface IProps {
  footerProps: IFooterProps
  showFooterLinks: boolean
}

const Footer = ({ footerProps, showFooterLinks }: IProps) => {
  const classes = useStyles()
  const hasMounted = useHasMounted()
  const data = useSharedContent()

  if (!hasMounted) {
    return null
  }

  return (
    <>
      <Grid item xs={12}>
        {footerProps.submitButton && (!footerProps?.hideSubmit ?? false) && (
          <Grid item xs={12}>
            <StyledButton
              className={classes.button}
              fullWidth
              label={footerProps.submitButton.label}
              type={footerProps.submitButton.type}
              onClickHandler={footerProps.submitButton.onClickHandler}
              isDisabled={footerProps.submitButton.isDisabled}
              onKeyPressed={footerProps.submitButton.onKeyPressed}
            />
          </Grid>
        )}
        {footerProps?.allowSkip && (
          <Grid item xs={12}>
            <StyledButton
              className={classes.button}
              fullWidth
              label={footerProps.skipButton?.label}
              type={ButtonType.SKIP}
              onClickHandler={footerProps?.skipButton?.onClickHandler!}
            />
          </Grid>
        )}
      </Grid>

      {showFooterLinks && (
        <>
          <hr style={{ width: "100%" }} />
          <Grid container spacing={3} className={classes.linkWrapper}>
            <Grid item>
              <Link className={classes.link} to={Paths.ABOUT}>
                {data?.aboutLinkLabel}
              </Link>
            </Grid>
            <Grid item>
              <Link className={classes.link} to={Paths.BACKGROUND}>
                {data?.backgroundLinkLabel}
              </Link>
            </Grid>
            <Grid item>
              <Link className={classes.link} to={Paths.PRIVACY}>
                {data?.privacyLinkLabel}
              </Link>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default Footer
